<template>
  <div class="timer-container">
    <!-- <div class="progress-bar">
      <div class="bomb" :style="{ left: progressBarWidth + '%' }"></div>
    </div> -->
    <!-- <button @click="stopTimer">Stop</button>
    <button @click="resumeTimer" :disabled="!isStopped">Resume</button> -->
    <p>진행 시간: {{ remainingTime }}초</p>
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'TimerComponent',
  setup() {
    const store = useStore();
    const totalTime = store.getters.getRemainingTime;
    const remainingTime = ref(totalTime);
    const intervalId = ref(null);
    const isStopped = ref(false);

    const progressBarWidth = computed(() => {
      return (remainingTime.value / totalTime) * 100;
    });

    const startTimer = () => {
      intervalId.value = setInterval(() => {
        remainingTime.value++;
        // if (remainingTime.value <= 0) {
        //   clearInterval(intervalId.value);
        //   remainingTime.value = 0; // 타이머가 0이 되면 멈춤
        // }
      }, 1000);
      isStopped.value = false;
    };

    const stopTimer = () => {
      if (intervalId.value) {
        clearInterval(intervalId.value);
        intervalId.value = null;
        isStopped.value = true;
      }
      store.dispatch('saveRemainingTime', remainingTime.value); // 남은 시간을 저장
    };

    // const resumeTimer = () => {
    //   if (isStopped.value) {
    //     startTimer();
    //   }
    // };

    onMounted(() => {
      startTimer();
    });

    onBeforeUnmount(() => {
      if (intervalId.value) {
        clearInterval(intervalId.value);
      }
    });

    return {
      remainingTime,
      progressBarWidth,
      stopTimer,
      // resumeTimer,
      isStopped
    };
  },
};
</script>

<style scoped>
.timer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progress-bar {
  position: relative;
  height: 20px;
  width: 100%;
  background-color: lightgray;
  border: 1px solid #000;
  margin-bottom: 10px;
  overflow: hidden;
}

.bomb {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  background-image: url('@/assets/bomb.png'); /* 폭탄 이미지 경로를 로컬 디렉토리로 수정 */
  background-size: contain;
  background-repeat: no-repeat;
  transition: left 1s linear;
}

button {
  margin: 5px;
}
</style>
