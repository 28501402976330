<template>
    <footer
      class="footer fixed bottom-0 left-0 z-20 w-full bg-white border-t border-gray-200 shadow dark:bg-gray-800 dark:border-gray-600"
    >
      <div class="w-full max-w-screen-xl mx-auto p-4">
        <a href="" class="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
          <img src="../assets/logo.png" class="h-16" alt="Flowbite Logo" />
          <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Ssolplan</span>
        </a>
        <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 <a href="" class="hover:underline">Ssolplan</a>. All Rights Reserved.</span>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: "FooterPage",
  };
  </script>
  
  <style scoped>
  .footer {
    min-height: 120px;
    height: 8%;
  }
  </style>
  