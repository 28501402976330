// store.js
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state: {
    remainingTime: 0, // 초기값을 0초로 설정
    answers: '' // answers를 저장할 배열
  },
  mutations: {
    setRemainingTime(state, time) {
      state.remainingTime = time;
    },
    addAnswer(state, answer) {
      state.answers.push(answer);
    },
    setAnswers(state, answers) {
      state.answers = answers;
    },
    clearAnswers(state) {
      state.answers = [];
      state.remainingTime = 0;
    },
    clearTime(state) {
      console.log('clearTime operated!!')
      state.remainingTime = 0;
    }
  },
  actions: {
    saveRemainingTime({ commit }, time) {
      commit('setRemainingTime', time);
    },
    addAnswer({ commit }, answer) {
      commit('addAnswer', answer);
    },
    setAnswers({ commit }, answers) {
      commit('setAnswers', answers);
    },
    clearAnswers({ commit }) {
      commit('clearAnswers');
    },
    clearTime({ commit }) {
      commit('clearTime');
    }
  },
  getters: {
    getRemainingTime: (state) => state.remainingTime,
    getAnswers: (state) => state.answers
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
});
