import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyA6LACcvSPNK6gFB6JrxxiazpWEZeNVKI0",
  authDomain: "firstfirebase-10dde.firebaseapp.com",
  projectId: "firstfirebase-10dde",
  storageBucket: "firstfirebase-10dde.appspot.com",
  messagingSenderId: "324811738222",
  appId: "1:324811738222:web:822ce51e0f6b48cd4faa7f",
  measurementId: "G-PJEPHBBHBD"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, collection, addDoc, storage, ref, uploadBytes, getDownloadURL };
